import {
  AirportFieldsFragment,
  AirportLocationsFieldsFragment,
  SearchAirportFieldsFragment,
} from '../../graphql/generated/graphql';

type UnifiedAirport =
  | AirportLocationsFieldsFragment
  | SearchAirportFieldsFragment
  | AirportFieldsFragment;

type GroupedAirports = Record<
  string,
  {
    countryName: string;
    countryId: string;
    countryFlag: string;
    airports: Array<UnifiedAirport>;
  }
>;

// Type guard to detect if it's wrapped in `airport` (SiteSiteAirport structure)
function isSiteAirport(
  airport: UnifiedAirport
): airport is AirportLocationsFieldsFragment {
  return 'airport' in airport;
}

export const GroupAirportsByCountry = (
  airports: Array<UnifiedAirport>
): GroupedAirports => {
  const groupedAirports: GroupedAirports = {};

  for (const airport of airports) {
    const location = isSiteAirport(airport)
      ? airport.airport?.location
      : airport.airport?.location;

    const country = location?.country;
    const countryName = country!.name;
    const countryId = country!.id;
    const countryFlag = country?.flagIconUrl || '';

    if (!groupedAirports[countryName]) {
      groupedAirports[countryName] = {
        countryName,
        countryId,
        countryFlag,
        airports: [],
      };
    }

    groupedAirports[countryName]?.airports.push(airport);
  }

  return groupedAirports;
};
